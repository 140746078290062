import React from 'react';
import { MainLayout } from '../components/layout/main-layout';
import Styled from 'styled-components';
import { Button } from '../components/buttons/button';
import { Helmet } from 'react-helmet';
import AboveFold from '../components/layout/above-fold';
import { FlexLayout, FlexChild } from 'xureact/lib/module/components/layout/flex-layout';

class Out extends React.Component {
  static propTypes = {};
  
  isWindowsAsset(asset) {
    return asset.browser_download_url.endsWith('.exe');
  }
  
  isLinuxAsset(asset) {
    return asset.browser_download_url.endsWith('.AppImage');
  }
  
  render() {
    const props = this.props;
    const latestRelease = props.pageContext.releases[0];
    const latestWindows = latestRelease.assets.find(this.isWindowsAsset);
    const latestLinux = latestRelease.assets.find(this.isLinuxAsset);
    
    return (
      <main className={props.className}>
        <Helmet>
          <title>HyperKeys</title>
          <link rel="canonical" href="https://www.xureilab.com/hyperkeys" />
          <meta name="description" content="Map any action to any shortcut. Pin windows, toggle audio output, find your phone, and more !"/>
          <meta property="og:image" content="https://www.bundlyzer.io/feature-chart.png" />
          <meta property="og:title" content="HyperKeys - Keyboard shortcuts on steroids" />
        </Helmet>
        <MainLayout>
          <AboveFold image="/img/squared_metal.png" className="intro">
            <h1 className="text-center">Download</h1>
                        <h4>Latest version: {latestRelease.name}</h4>
            <div style={{ width: 300, margin: '0 auto'}}>
              <FlexLayout>
                <FlexChild grow={1}>
                  <a href={latestWindows.browser_download_url}>
                    <Button color="primary" size="big">Windows</Button>
                  </a>
                </FlexChild>
                <FlexChild grow={1}>
                  <a href={latestLinux.browser_download_url}>
                    <Button color="primary">Linux</Button>
                  </a>
                </FlexChild>
              </FlexLayout>
            </div>
          </AboveFold>
          
          <section id="releases" className="text-center">
            <h2>All releases</h2>
                        <p>
                            All the releases can also be found on {' '}
                            <a href="https://github.com/xurei/hyperkeys/releases" target="_blank">the Github repository</a>.
                        </p>
            
            {props.pageContext.releases.filter(release => !release.draft).map(release => {
              const assetWindows = release.assets.find(this.isWindowsAsset);
              const assetLinux = release.assets.find(this.isLinuxAsset);
                
                return (
                    <div className="release" key={release.id}>
                                    {/*<pre style={{background:'#fff', textAlign: 'left'}}>{JSON.stringify(release, null, '  ')}</pre>*/}
                                    <h3>{release.name}</h3>
                                    <ul style={{maxWidth: 400, textAlign: 'left', margin: '0 auto'}}>
                                      {assetWindows && (<li>Windows : <a href={assetWindows.browser_download_url}>{assetWindows.name}</a></li>)}
                                      {assetLinux && (<li>Linux : <a href={assetLinux.browser_download_url}>{assetLinux.name}</a></li>)}
                                    </ul>
                                    <br/>
                                    <br/>
                                </div>
                            );
                        })}
          </section>
        </MainLayout>
      </main>
    );
  }
  
  shouldComponentUpdate() {
    return true;
  }
}

//language=SCSS
Out = Styled(Out)`
& {
  top: 0;
  
  .intro {
    .btn {
      padding: 10px 25px;
    }
  }
}
`;

export default Out;
